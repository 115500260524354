/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyAk_rNBqf4ufwMtXbyXdBFH7wHUN4fx-N4",
  "appId": "1:170739922653:web:d898c5f9744fe98a320d34",
  "authDomain": "schooldog-i-stephens-ga.firebaseapp.com",
  "measurementId": "G-VKT30EE9Z8",
  "messagingSenderId": "170739922653",
  "project": "schooldog-i-stephens-ga",
  "projectId": "schooldog-i-stephens-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-stephens-ga.appspot.com"
}
